import React from "react";
import { graphql } from "gatsby";

import Section from "components/common/Section/Index";
import Link from "components/common/Link";
import Text from "components/common/Text";
import Page from "components/Page";

const NotFoundPage = ({ data }) => {
  const { title, description } = data.markdownRemark.frontmatter.page;

  return (
    <Page
      id="notFound"
      metadata={{
        title,
        description,
      }}
    >
      <Section>
        <Section.Container className="m-auto min-h-screen flex flex-col justify-center items-center">
          <Text
            className="font-bold"
            color="cod-gray"
            size="6xl"
            headlineTag="h1"
            position="center"
          >
            Error 404
          </Text>
          <Link className="text-cod-gray block mt-5" href="/" fit>
            <Text color="cod-gray">Back to homepage</Text>
          </Link>
        </Section.Container>
      </Section>
    </Page>
  );
};

export const query = graphql`
  query ($language: String!) {
    markdownRemark(frontmatter: { page: { id: { eq: "notFound" } } }) {
      ...PageInformation
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
