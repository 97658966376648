import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Link = ({ href, className, children, target, onClick, fit }) => (
  <a
    href={href}
    onClick={onClick}
    className={classNames(
      "Link transition-all text-primary-light hover:opacity-80",
      className,
      {
        "max-w-fit": fit,
      }
    )}
    target={target}
  >
    {children}
  </a>
);

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  target: PropTypes.oneOf(["_self", "_blank", "_parent", "_top"]),
  onClick: PropTypes.func,
  fit: PropTypes.bool,
};

Link.defaultProps = {
  href: "",
  className: "",
  target: "_self",
  onClick: null,
  fit: false,
};

export default Link;
